<script>
import Layout from "@/router/layouts/main";
import PageHeader from '@/components/page-header'
import appConfig from "@/app.config";
import UserService from '@/services/user'
// import defaultStoreImage from '../../../assets/images/default-store.png'

export default {
  page: {
    title: "Detalhes do Prestador",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { Layout, PageHeader },
  data() {
    return {
      title: "Detalhes do Prestador",
      user: {
        address: {},
        bankData: {},
        services: [],
      },
      userId: '',
    };
  },
  mounted() {
    this.userId = this.$route.params.id;
    this.load()
  },
  methods: {
    load(hideLoading = false) {
      let loader;
      
      if (!hideLoading) {
        loader = this.$loading.show();
      }

      UserService.getUserById(this.userId).then(res => {
        this.user = res.data;

        if (loader) {
          loader.hide();
        }
      }).catch(() => {
        if (loader) {
          loader.hide();
        }
      })
    },
    saveConfig() {
      let loader = this.$loading.show();

      const data = {
        enableBankSlipPayment: this.user.enableBankSlipPayment,
      }

      UserService.updateProvider(this.userId, data).then(res => {
        this.user = res.data;

        this.$toast.open('Configurações do prestador atualizadas');

        this.load(true);

        loader.hide();
      }).catch(() => {
        loader.hide();
        this.$toast.error('Ocorreu um erro ao executar essa ação. Tente novamente.');
      })
    },

    saveMain() {
      let loader = this.$loading.show();

      const data = {
        name: this.user.name,
        cpf: this.user.cpf,
        email: this.user.email,
        phoneNumber: this.user.phoneNumber,
      }

      UserService.updateProvider(this.userId, data).then(() => {
        this.$toast.open('Dados atualizados');
        loader.hide();
      }).catch(() => {
        loader.hide();
        this.$toast.error('Ocorreu um erro ao executar essa ação. Tente novamente.');
      })
    },

    saveAddress() {
      let loader = this.$loading.show();

      const data = {
        address: this.user.address,
      }

      UserService.updateProvider(this.userId, data).then(() => {
        this.$toast.open('Dados atualizados');
        loader.hide();
      }).catch(() => {
        loader.hide();
        this.$toast.error('Ocorreu um erro ao executar essa ação. Tente novamente.');
      })
    },

    saveBankData() {
      let loader = this.$loading.show();

      const data = {
        bankData: this.user.bankData,
      }

      UserService.updateProvider(this.userId, data).then(() => {
        this.$toast.open('Dados atualizados');
        loader.hide();
      }).catch(() => {
        loader.hide();
        this.$toast.error('Ocorreu um erro ao executar essa ação. Tente novamente.');
      })
    }
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" />

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Dados Principais</h4>
            <!-- <p class="card-title-desc">Preencha as informações principais da loja e a localização.</p> -->

            <div class="row mt-4">
              <div class="col-sm-2 text-center">
                <img
                  class="rounded-circle avatar-xl user-avatar"
                  :src="user.picture"
                  data-holder-rendered="true"
                  v-if="user.picture"
                />

                <div class="rounded-circle avatar-xs m-1 avatar-letter-big" v-if="!user.picture && user.name">{{user.name.toUpperCase().substring(0,1)}}</div>
              </div>

              <div class="col-sm-10">
                <div class="row">
                  <div class="col-sm-12">
                    <div class="form-group">
                      <label for="name">Nome</label>
                      <input id="name" name="name" type="text" class="form-control" v-model="user.name" />
                    </div>
                  </div>
                </div>
                
                <div class="row">
                  <div class="col-sm-3">
                    <div class="form-group">
                      <label for="cpf">CPF</label>
                      <input id="cpf" name="cpf" type="tel" class="form-control" v-mask="'###.###.###-##'" v-model="user.cpf" />
                    </div>
                  </div>

                  <div class="col-sm-5">
                    <div class="form-group">
                      <label for="manufacturerbrand">E-mail</label>
                      <input id="email" name="email" type="email" class="form-control" v-model="user.email" />
                    </div>
                  </div>

                  <div class="col-sm-4">
                    <div class="form-group">
                      <label for="phoneNumber">Telefone</label>
                      <input id="phoneNumber" name="phoneNumber" type="tel" class="form-control" v-mask="'(##) #####-####'" v-model="user.phoneNumber" />
                    </div>
                  </div>
                </div>

                <button class="btn btn-primary mr-1 mt-3" @click="saveMain">Salvar Dados Principais</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Endereço</h4>
            <!-- <p class="card-title-desc">Preencha as informações principais da loja e a localização.</p> -->

            <div class="row mt-4">
              <div class="col-sm-12">
                <div class="row">
                  <div class="col-sm-6">
                    <div class="form-group">
                      <label for="name">Endereço (logradouro)</label>
                      <input id="name" name="name" type="text" class="form-control" v-model="user.address.street" />
                    </div>
                  </div>
                  <div class="col-sm-2">
                    <div class="form-group">
                      <label for="manufacturername">Número</label>
                      <input
                        id="cnpj"
                        name="cnpj"
                        type="text"
                        class="form-control"
                        v-model="user.address.number"
                      />
                    </div>
                  </div>
                  <div class="col-sm-4">
                    <div class="form-group">
                      <label for="manufacturerbrand">Complemento</label>
                      <input
                        id="email"
                        name="email"
                        type="email"
                        class="form-control"
                        v-model="user.address.complement"
                      />
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-sm-4">
                    <div class="form-group">
                      <label for="district">Bairro</label>
                      <input id="district" name="district" type="text" class="form-control" v-model="user.address.district" />
                    </div>
                  </div>
                  <div class="col-sm-4">
                    <div class="form-group">
                      <label for="city">Cidade</label>
                      <input
                        id="city"
                        name="city"
                        type="text"
                        class="form-control"
                        v-model="user.address.city"
                      />
                    </div>
                  </div>
                  <div class="col-sm-1">
                    <div class="form-group">
                      <label for="state">UF</label>
                      <input
                        id="state"
                        name="state"
                        type="state"
                        class="form-control"
                        v-model="user.address.state"
                      />
                    </div>
                  </div>
                  <div class="col-sm-3">
                    <div class="form-group">
                      <label for="manufacturerbrand">CEP</label>
                      <input
                        id="zipcode"
                        name="zipcode"
                        type="zipcode"
                        class="form-control"
                        v-model="user.address.zipcode"
                        v-mask="'#####-###'"
                      />
                    </div>
                  </div>
                </div>
                <button class="btn btn-primary mr-1 mt-3" @click="saveAddress">Salvar Endereço</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Dados Bancários</h4>
            <!-- <p class="card-title-desc">Preencha as informações principais da loja e a localização.</p> -->

            <div class="row mt-4">
              <div class="col-sm-12">
                <div class="row">
                  <div class="col-sm-1">
                    <div class="form-group">
                      <label for="bankCode">Banco</label>
                      <input id="bankCode" name="bankCode" type="text" class="form-control" v-model="user.bankData.bankCode" />
                    </div>
                  </div>
              
                  <div class="col-sm-2">
                    <div class="form-group">
                      <label for="agency">Agência</label>
                      <input id="agency" name="agency" type="text" class="form-control" v-model="user.bankData.agency" />
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-sm-2">
                    <div class="form-group">
                      <label for="accountType">Tipo da Conta</label>

                      <select id="accountType" name="accountType" class="form-control" v-model="user.bankData.accountType"> 
                        <option value="0">Conta Corrente</option>
                        <option value="1">Conta Poupança</option>
                      </select>
                    </div>
                  </div>

                  <div class="col-sm-3">
                    <div class="form-group">
                      <label for="account">Número da Conta</label>
                      <input id="account" name="account" type="text" class="form-control" v-model="user.bankData.account" />
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-sm-2">
                    <div class="form-group">
                      <label for="pixKeyType">Tipo Chave PIX</label>

                      <select id="pixKeyType" name="pixKeyType" class="form-control" v-model="user.bankData.pixKeyType"> 
                        <option value="cpf">CPF</option>
                        <option value="telefone">Telefone</option>
                        <option value="email">E-mail</option>
                        <option value="aleatoria">Aleatória</option>
                      </select>
                    </div>
                  </div>
              
                  <div class="col-sm-3">
                    <div class="form-group">
                      <label for="pixKey">Chave PIX</label>
                      <input id="pixKey" name="pixKey" type="text" class="form-control" v-model="user.bankData.pixKey" />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <button class="btn btn-primary mr-1 mt-3" @click="saveBankData">Salvar Dados Bancários</button>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Serviços Oferecidos</h4>
            <!-- <p class="card-title-desc">Preencha as informações principais da loja e a localização.</p> -->

            <form>
              <div class="row mt-4" v-for="service in user.services" :key="service._id">
                <div class="col-sm-12">
                  <div class="row">
                    <div class="col-sm-3">
                      <div class="form-group">
                        <label for="name">Serviço</label>
                        <input id="name" name="name" type="text" class="form-control" :value="service.serviceType.description" readonly />
                      </div>
                    </div>
                
                    <div class="col-sm-2">
                      <div class="form-group">
                        <label for="cpf">Valor da Diária</label>
                        <input class="form-control" :value="service.dailyValue.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })" readonly />
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Configurações</h4>
            <p class="card-title-desc">Defina as configurações e preferências específicas para o prestador de serviço</p>

            <form>
              <div class="row mt-4">
                <div class="col-sm-12">
                  <div class="form-group">
                    <div class="custom-control custom-checkbox mb-3">
                      <input type="checkbox" class="custom-control-input" id="enableBankSlipPayment" v-model="user.enableBankSlipPayment" />
                      <label class="custom-control-label" for="enableBankSlipPayment">Habilitar pagamento por boleto antes da aprovação Braspag</label>
                    </div>
                  </div>
                </div>
              </div>
            </form>
            <button type="submit" class="btn btn-primary mr-1 mt-4" @click="saveConfig">Salvar Configurações</button>
          </div>
        </div>
      </div>
    </div>

    <div class="row mt-2 mb-4 align-items-center">
      <div class="col-sm-6">
        <router-link
          tag="a"
          to="/providers"
          class="btn text-muted d-none d-sm-inline-block btn-link"
        >
          <i class="mdi mdi-arrow-left mr-1"></i> Voltar para lista de prestadores
        </router-link>
      </div>
    </div>
  </Layout>
</template>

<style lang="scss">
  img.user-avatar {
    object-fit: cover;
  }

  .form-control[readonly] {
    background-color: #f1f1f1;
  }

  .avatar-letter-big {
    height: 120px;
    width: 120px;
    min-height: 120px;
    min-width: 120px;
    background-color: rgb(241, 101, 73, 0.3);
    color: #F16549;
    line-height: 120px;
    text-align: center;
    font-weight: bold;
    font-size: 30px;
  }
</style>